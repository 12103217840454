import React, { useEffect, useMemo } from "react";
import { Box, Dialog } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

type Props = {
  open: boolean;
  onClose: () => void;
  nextStepUrl?: string;
};

const defaultNextStepUrl = "https://nextstep.au.reachout.com";
const NextStepModal = ({
  open,
  onClose,
  nextStepUrl = defaultNextStepUrl,
}: Props) => {
  const messageHandler = useMemo<Record<string, Function>>(
    () => ({
      closeNextStep: onClose,
    }),
    [onClose]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    function onMessage(event: MessageEvent) {
      const data = event.data;

      if (data.lib === "nextStepEmbedder") {
        if (data.func in messageHandler) messageHandler[data.func]();
        else console.warn("Unhandled Nextstep message:", JSON.stringify(data));
      }
    }
    window.addEventListener("message", onMessage, false);

    return () => {
      window.removeEventListener("message", onMessage, false);
    };
  }, [messageHandler]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="nextstep-modal-title"
      aria-describedby="nextstep-modal-description"
      sx={{
        // ensure NextStep doesn't get hidden by cookie banner
        zIndex: (theme) => theme.zIndex.snackbar + 1,
        "& .MuiDialog-paper": {
          position: "relative",
          flexGrow: { xs: 1, sm: "unset" },
          m: { xs: 0, sm: 4 },
          borderRadius: { xs: 0, sm: "24px" },
          minWidth: 375,
          height: "100%",
          maxHeight: { xs: "none", sm: 800 },
          background: "#0073cf",
        },
      }}
      onClose={onClose}
    >
      <Box sx={visuallyHidden}>
        <h2 id="nextstep-modal-title">Welcome to NextStep</h2>
        <p id="nextstep-modal-description">
          NextStep will guide you to personalised support in 3 simple steps.
        </p>
      </Box>
      <Box
        component="iframe"
        id="nextstep-container"
        src={nextStepUrl}
        frameBorder="0"
        title="NextStep"
        sx={{ width: "100%", height: "100%" }}
        ref={(node: HTMLElement) => node && node.focus()}
      ></Box>
    </Dialog>
  );
};

export default NextStepModal;
