import React, { useState, FC, useEffect } from "react";
import { getHashRoute } from "@/utils/utils";

export const audiences = ["young-people", "parents-carers", "schools"] as const;

type AudienceContextValues = {
  audience?: Audience;
  setAudience: (audience: Audience) => void;
};
const AudienceContext = React.createContext<AudienceContextValues>({
  audience: "young-people",
  setAudience: () => null,
});

type Props = {
  children: React.ReactNode;
};

export default AudienceContext;

export function getInitialAudience() {
  const currentHash = getHashRoute() as Audience;

  if (audiences.includes(currentHash)) return currentHash;
  return "young-people";
}

export const AudienceProvider: FC<Props> = ({ children }) => {
  const [audience, setAudience] = useState<Audience>("young-people");
  useEffect(() => setAudience(getInitialAudience), []);

  return (
    <AudienceContext.Provider value={{ audience, setAudience }}>
      {children}
    </AudienceContext.Provider>
  );
};
