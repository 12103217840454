import * as React from "react";

import MuiThemeProvider from "@/contexts/MuiThemeProvider";
import { AudienceProvider } from "./AudienceProvider";
import NextStepProvider from "./NextStepProvider";
import ImageProvider from "./ImageContext";
import { L2ModalProvider } from "./L2ModalProvider";

type Props = {
  children: React.ReactNode;
};

export const Providers: React.FC<Props> = ({ children }) => {
  return (
    <ImageProvider>
      <MuiThemeProvider>
        <AudienceProvider>
          <L2ModalProvider>
            <NextStepProvider>{children}</NextStepProvider>
          </L2ModalProvider>
        </AudienceProvider>
      </MuiThemeProvider>
    </ImageProvider>
  );
};
