import { L2ModalName } from "@/types/l2Dialog";
import React, { FC, createContext, PropsWithChildren, useState } from "react";

type L2ModalContextValues = {
  l2Open?: L2ModalName;
  setL2Open: (modalName: L2ModalName) => void;
};

const L2ModalContext = createContext<L2ModalContextValues>({
  l2Open: "",
  setL2Open: () => null,
});

export default L2ModalContext;

export const L2ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [l2Open, setL2Open] = useState<L2ModalName>("");

  return (
    <L2ModalContext.Provider
      value={{
        l2Open,
        setL2Open,
      }}
    >
      {children}
    </L2ModalContext.Provider>
  );
};
