import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import NextStepModal from "@/components/NextStepModal";
import { useA11yPortalFix } from "@/utils/useA11yPortalFix";

function isCurrentHashNextStep() {
  return typeof window !== "undefined" && window.location.hash === "#nextstep";
}

export const NextStepContext = createContext({
  showNextStep: (_e: React.MouseEvent) => {},
});

const NextStepProvider = ({ children }: PropsWithChildren<{}>) => {
  const [showNextStep, setShowNextStep] = useState(isCurrentHashNextStep);

  useEffect(() => {
    if (showNextStep && window.heap) {
      window.heap.track("nextstep-opened");
    }
  }, [showNextStep]);

  useA11yPortalFix(showNextStep);

  return (
    <NextStepContext.Provider
      value={{
        showNextStep: (e: React.MouseEvent) => {
          e.preventDefault();
          setShowNextStep(true);
        },
      }}
    >
      {children}
      <NextStepModal
        nextStepUrl={process.env.GATSBY_NEXTSTEP_URL}
        open={showNextStep}
        onClose={() => setShowNextStep(false)}
      />
    </NextStepContext.Provider>
  );
};

export default NextStepProvider;
