import { useEffect } from "react";

// Workaround for disablePortal bug hiding dialogs from Screen reader
// see: https://github.com/mui/material-ui/issues/19450

// NOTE: the bug triggers when ANY dialog opens, as long as there is a modal on the page with disablePortal
// so we also need to call this hook when regular dialogs like checkin or NextStep are opened
export const useA11yPortalFix = (open?: boolean) => {
  useEffect(() => {
    if (open) {
      // because of the bug aria-hidden true is added to the 'react root' in our case the div with id '___gatsby'
      // we just remove that to ensure the content is visible to accessibility tools
      document.getElementById("___gatsby")?.removeAttribute("aria-hidden");
    }
  }, [open]);
};
