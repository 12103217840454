// unless otherwise specified the main colour is the 500 tone of the colour from the style guide.
// unless otherwise specified light and dark variants are 2 tones off from the main colour (e.g. 300 and 700)

import { PaletteColor } from "@mui/material";

// used by MUI to extend the built in `colour` prop
export interface BrandColours {
  navy: true;
  buff: true;
  plum: true;
  violet: true;
  peri: true;
  cherry: true;
  coral: true;
  blush: true;
  mallard: true;
  jade: true;
  mint: true;
  ochre: true;
  marigold: true;
  lemon: true;
  cobalt: true;
  french: true;
  sky: true;
  ice: true;
  white: true;
  coreGrey: true;
}
export type BrandColour = keyof BrandColours;

export const brandColours: Record<BrandColour, PaletteColor> = {
  navy: {
    light: "#405169",
    main: "#001737",
    dark: "#001129",
    contrastText: "#ffffff",
  },
  buff: {
    light: "#F9F5F0",
    main: "#F7F1EB",
    dark: "#B9B5B0",
    contrastText: "#001737",
  },
  plum: {
    light: "#9477EC",
    main: "#7049E6",
    dark: "#5437AC",
    contrastText: "#ffffff",
  },
  violet: {
    light: "#C185FC",
    main: "#AC5CFB",
    dark: "#8145BC",
    contrastText: "#001737",
  },
  peri: {
    light: "#BFB8FF",
    main: "#AAA0FF",
    dark: "#8078BF",
    contrastText: "#001737",
  },
  cherry: {
    light: "#FF8074",
    main: "#FF5546",
    dark: "#BF4034",
    contrastText: "#001737",
  },
  coral: {
    light: "#FF8F95",
    main: "#FF6971",
    dark: "#BF4F55",
    contrastText: "#001737",
  },
  blush: {
    light: "#FEB6B7",
    main: "#FE9E9F",
    dark: "#BF7777",
    contrastText: "#001737",
  },
  mallard: {
    light: "#509598",
    main: "#167176",
    dark: "#105558",
    contrastText: "#ffffff",
  },
  jade: {
    light: "#5EC7A1",
    main: "#28B482",
    dark: "#1E8761",
    contrastText: "#001737",
  },
  mint: {
    light: "#BFE8A1",
    main: "#AAE182",
    dark: "#80A961",
    contrastText: "#001737",
  },
  ochre: {
    light: "#DDA956",
    main: "#D28C1E",
    dark: "#9E6917",
    contrastText: "#001737",
  },
  marigold: {
    light: "#FFD940",
    main: "#FFCD00",
    dark: "#BF9A00",
    contrastText: "#001737",
  },
  lemon: {
    light: "#FFF483",
    main: "#FFF05A",
    dark: "#BFB443",
    contrastText: "#001737",
  },
  cobalt: {
    light: "#717BF7",
    main: "#424FF4",
    dark: "#313BB7",
    contrastText: "#ffffff",
  },
  french: {
    light: "#709FF2",
    main: "#407FEE",
    dark: "#305FB2",
    contrastText: "#ffffff",
  },
  sky: {
    light: "#83CADD",
    main: "#5AB9D2",
    dark: "#438B9E",
    contrastText: "#001737",
  },
  ice: {
    light: "#C1E4D6",
    main: "#ADDBC9",
    dark: "#82A497",
    contrastText: "#001737",
  },

  white: {
    light: "#ffffff",
    main: "#ffffff",
    dark: "#E5E5E5",
    contrastText: "#001737",
  },

  coreGrey: {
    light: "#666D7F",
    main: "#333C54",
    dark: "#262D3F",
    contrastText: "#ffffff",
  },
};

export const colours = {
  ...brandColours,
  navy: {
    ...brandColours.navy,
    lightest: "#E5E8EB", // navy 50
    tint: "#001737e6", // 90% opacity main
    darkest: "#00060E", // navy 900
  },
  grey: {
    lightest: "#EBEBEE", // grey 50
    lighter: "#CCCED4", // grey 100
    light: "#666D7F",
    main: "#333C54",
    dark: "#262D3F",
  },
  ice: {
    ...brandColours.ice,
    lightest: "#D6EDE4", //ice 20
  },
  text: {
    light: "#ffffff",
    dark: "#001737",
  },
  background: {
    default: "#001737",
    light: "#ffffff",
    dark: "#00060E",
  },
};

// slight MUI hack to force it to output multiple versions of the `outline` property
// so that we can set fallbacks for browsers that don't support webkit-focus-ring-color or Highlight
// (prefers the later option if valid, otherwise moves up the list until it finds a valid option)

// expected outcome:
//   outline: 2px auto #7049E6;
//   outline: 2px auto Highlight;
//   outline: 2px auto -webkit-focus-ring-color;

export const outlineStyle = [
  `2px auto ${colours.plum.main}`,
  "2px auto Highlight",
  "2px auto -webkit-focus-ring-color",
].join("; outline: ");
