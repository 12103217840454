import { PaletteOptions } from "@mui/material";
import {
  Theme,
  createTheme,
  alpha,
  experimental_sx as sx,
} from "@mui/material/styles";
import { brandColours, colours, outlineStyle } from "./colours";

export { colours } from "./colours";

const muiButtonFocusVisible = (theme: any, color: any) => ({
  backgroundColor: theme.palette[color || "primary"].dark,
  color: theme.palette[color || "primary"].contrastText,
  border: `2px solid ${theme.palette[color || "primary"].main}`,

  "& svg": {
    transition: "all .2s",
    backgroundColor: "#fff",
    fill: "#000",
  },
  // TODO: For now, focus state is now a copy of "active" state
  // Design team's work covers one extra state
  // which is commented below

  // border: `2px solid ${theme.palette[color || "primary"].main}`,
  // color: `${
  //   // TODO: Fix contrast colours
  //   theme.palette[color || "primary"].main
  // }`,
  // backgroundColor: "#fff",
  // "& svg": {
  //   transition: "all .2s",
  //   backgroundColor: theme.palette[color || "primary"].main,
  //   fill: "#fff",
  // },
});

const components: Theme["components"] = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        whiteSpace: "normal",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 4,
        color: colours.plum.main,

        "& svg": {
          width: "2rem",
          height: "2rem",
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: colours.navy.main,
        "&.Mui-focused": {
          color: colours.navy.main,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:focus": {
          outline: outlineStyle,
        },
        "&:focus:not(:focus-visible)": {
          outline: "unset",
        },
        "&:focus-visible": {
          outline: outlineStyle,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
      disableRipple: true,
    },
    // create button with icon as new mui variant
    variants: [
      {
        props: { variant: "hero" },
        // MUI doesn't include ownerState in the types for this function, so use any to work around this
        style: ({ theme, ownerState: { color } }: any) => ({
          // core button state
          textTransform: "none",
          textAlign: "left",
          borderRadius: 200,
          backgroundColor: theme.palette[color || "primary"].main,
          color: theme.palette[color || "primary"].contrastText,
          fontWeight: 600,
          fontSize: [15, 18],
          lineHeight: [21, 24],
          border: "2px solid transparent",
          outline: "none !important",
          justifyContent: "space-between",
          padding: "12px 12px 12px 32px",
          width: "100%",
          "&:hover": {
            backgroundColor: theme.palette[color || "primary"].light,
            "& svg": {
              transition: "all .2s",
            },
          },
          "&:focus": muiButtonFocusVisible(theme, color),
          "&:focus:not(:focus-visible)": {
            backgroundColor: theme.palette[color || "primary"].main,
            color: theme.palette[color || "primary"].contrastText,
            border: "2px solid transparent",
          },
          "&:focus-visible": muiButtonFocusVisible(theme, color),
          "&:active": {
            backgroundColor: theme.palette[color || "primary"].dark,
            color: theme.palette[color || "primary"].contrastText,

            "& svg": {
              transition: "all .2s",
              backgroundColor: "#fff",
              fill: "#000",
            },
          },

          // core button child elements
          "& svg": {
            backgroundColor: colours.navy.main,
            minWidth: 32,
            height: 32,
            borderRadius: "100px",
            marginRight: 4,
            marginLeft: 12,
            padding: 8,
            transition: "all .4s",
            fill: "#fff",
          },
        }),
      },
    ],
    styleOverrides: {
      root: {
        textTransform: "none",
        borderRadius: 30,
        "&:focus": {
          outline: outlineStyle,
        },
        "&:focus:not(:focus-visible)": {
          outline: "unset",
        },
        "&:focus-visible": {
          outline: outlineStyle,
        },
        "&.Mui-disabled": {
          background: colours.grey.lightest,
          color: colours.grey.light,
        },
      },

      contained: ({ theme, ownerState: { color } }) => {
        // only apply hover/pressed styles if color is something we can access
        // light/dark modes of in the theme
        if (color && color !== "inherit") {
          const paletteColor = theme.palette[color || "primary"];
          return {
            "&:hover": { backgroundColor: paletteColor.light },
            "&:active": { backgroundColor: paletteColor.dark },
          };
        }
      },
      containedSecondary: ({ theme }) => ({
        "&.Mui-disabled": {
          background: theme.palette.grey[200],
          color: theme.palette.text.disabled,
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      // indicator state is handled by selected tab
      indicator: { display: "none" },
      // allow room for the focus ring to not get cut off
      flexContainer: sx({ p: 1 }),
    },
  },

  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: sx({
        backgroundColor: "none",
        color: colours.navy.main,
        borderRadius: 30,
        px: [2, 4],
        py: 0,
        textTransform: "initial",
        fontWeight: 400,
        fontSize: { xs: 14, sm: 16, lg: 18 },
        "&:hover": { bgcolor: alpha(colours.navy.main, 0.1) },
        "&:active": { bgcolor: alpha(colours.navy.main, 0.3) },
        "&.Mui-selected": {
          color: "navy.contrastText",
          bgcolor: "navy.main",
          "&:active": { bgcolor: "navy.dark" },
          "&.Mui-focusVisible": {
            bgcolor: "navy.light",
          },
        },
        "&.Mui-focusVisible": {
          outline: outlineStyle,
        },
        "&:first-of-type": {
          marginRight: "4px",
        },
        "&:last-of-type": {
          marginLeft: "4px",
        },
      }),
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
      },
    },
  },
};

const workSansFont = "'Work Sans', Arial, sans-serif";
const beatriceFont = "Beatrice, Arial, sans-serif";

const typography: Partial<Theme["typography"]> = {
  fontFamily: workSansFont,
  h1: {
    fontFamily: beatriceFont,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  h2: {
    fontFamily: beatriceFont,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  h3: { fontFamily: beatriceFont, fontSize: 24, lineHeight: 1.4 },
  h4: {
    fontFamily: beatriceFont,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
  },
  h5: {
    fontFamily: beatriceFont,
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 600,
  },
  h6: {
    fontFamily: beatriceFont,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.6,
  },
  body1: {
    fontSize: 16,
    whiteSpace: "pre-line",
    lineHeight: 1.6,
  },
  body2: {
    fontSize: 18,
    whiteSpace: "pre-line",
    lineHeight: 1.6,
    fontWeight: 600,
  },
  body3: {
    fontSize: 20,
    fontWeight: 400,
  },
  caption: {
    fontSize: 14,
    lineHeight: 1.6,
  },
  button: {
    fontSize: 16,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 400,
  },
};

const contrastThreshold = 4.5;

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: colours.plum,
    secondary: colours.plum,
    error: { main: colours.cherry.dark },
    success: colours.plum,
    text: { primary: colours.navy.main },
    background: {
      default: colours.buff.main,
      paper: colours.white.main,
    },
    ...brandColours,
    contrastThreshold,
  },
  components,
  typography,
});

// add breakpoint overrides to h1
Object.assign(lightTheme.typography.h1, {
  [lightTheme.breakpoints.down("lg")]: {
    fontSize: "32px",
  },
});

Object.assign(lightTheme.typography.body2, {
  [lightTheme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
});

Object.assign(lightTheme.typography.body3, {
  [lightTheme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
});

Object.assign(lightTheme.typography.h2, {
  [lightTheme.breakpoints.down("sm")]: {
    fontSize: "24px",
  },
});

Object.assign(lightTheme.typography.h3, {
  [lightTheme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
});
