export { wrapRootElement } from "./wrapRootElement";

export const onRouteUpdate = () => {
  // document.referrer is empty in gatsby, meaning we don't know where the user came from
  // e.g. (third party e.g. google.com, or au.reachout.com), which might be useful
  // in the event we are doing any programmatic navigation e.g. for L2 Modals
  // so this change now populates `window.previousPageUrl` for access to this
  window.locations = window.locations || [document.referrer];
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href);
  }
  window.previousPageUrl = window.locations[locations.length - 2];
};
