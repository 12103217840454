import { GatsbyImageProps } from "gatsby-plugin-image";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

type ImageContextValues = Record<string, GatsbyImageProps["image"]>;

export const ImageContext = createContext<{
  images: ImageContextValues;
  setImages: Dispatch<SetStateAction<ImageContextValues>>;
}>({ images: {}, setImages: () => {} });

const ImageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [images, setImages] = useState<ImageContextValues>({});

  return (
    <ImageContext.Provider
      value={{
        images,
        setImages,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export default ImageProvider;
