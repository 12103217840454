export const isBrowser = () => typeof window !== "undefined";
export const getHashRoute = () =>
  isBrowser() ? window.location.hash?.slice(1) : "";

export const isReachOutWebsite = (previousPageUrl: string) =>
  [
    "http://localhost:8000/",
    "http://localhost:9000/",
    "https://au.reachout.com/",
    "https://test.au.reachout.com/",
    "https://dev.au.reachout.com/",
    "https://arrival-experience.dev.reachout.com/",
    "https://arrival-experience.reachout.com/",
    "https://arrival-experience.test.reachout.com/",
  ].includes(previousPageUrl);
